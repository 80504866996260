/* open-sans-300normal - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Open Sans Light '),
    local('Open Sans-Light'),
    url(/static/media/open-sans-latin-300.60c86674.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-300.521d17bc.woff) format('woff'); /* Modern Browsers */
}

/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Open Sans Light italic'),
    local('Open Sans-Lightitalic'),
    url(/static/media/open-sans-latin-300italic.06bbd318.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-300italic.8a648ff3.woff) format('woff'); /* Modern Browsers */
}

/* open-sans-400normal - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Open Sans Regular '),
    local('Open Sans-Regular'),
    url(/static/media/open-sans-latin-400.cffb686d.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-400.bf2d0783.woff) format('woff'); /* Modern Browsers */
}

/* open-sans-400italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Open Sans Regular italic'),
    local('Open Sans-Regularitalic'),
    url(/static/media/open-sans-latin-400italic.987032ea.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-400italic.db70d0b9.woff) format('woff'); /* Modern Browsers */
}

/* open-sans-600normal - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Open Sans SemiBold '),
    local('Open Sans-SemiBold'),
    url(/static/media/open-sans-latin-600.223a277b.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-600.1cd5320f.woff) format('woff'); /* Modern Browsers */
}

/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Open Sans SemiBold italic'),
    local('Open Sans-SemiBolditalic'),
    url(/static/media/open-sans-latin-600italic.4950a720.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-600italic.318ea1ad.woff) format('woff'); /* Modern Browsers */
}

/* open-sans-700normal - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Open Sans Bold '),
    local('Open Sans-Bold'),
    url(/static/media/open-sans-latin-700.d08c09f2.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-700.623e3205.woff) format('woff'); /* Modern Browsers */
}

/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Open Sans Bold italic'),
    local('Open Sans-Bolditalic'),
    url(/static/media/open-sans-latin-700italic.c02f5da6.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-700italic.72e19cbb.woff) format('woff'); /* Modern Browsers */
}

/* open-sans-800normal - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Open Sans ExtraBold '),
    local('Open Sans-ExtraBold'),
    url(/static/media/open-sans-latin-800.aaeffaf2.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-800.c6aa0c4a.woff) format('woff'); /* Modern Browsers */
}

/* open-sans-800italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Open Sans ExtraBold italic'),
    local('Open Sans-ExtraBolditalic'),
    url(/static/media/open-sans-latin-800italic.6b3973ff.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-800italic.79b58175.woff) format('woff'); /* Modern Browsers */
}


